.navigation {
  &__open {
    background-color: var(--color-gray);
    color: var(--color-white);
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    min-width: 100vw;
    min-height: 100vh;
    transition: all 0.4s ease-out;
    z-index: 10000;
    display: flex;

    &Close {
      @extend .navigation__open;

      top: -2000px;
      visibility: hidden;
    }
  }

  &__link {
    display: none;

    @media screen and (min-width: 996px) {
      display: flex;
    }
  }

  &__button {
    position: relative;
    display: inline;
    background-color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 15000;

    @media screen and (min-width: 996px) {
      display: none;
    }

    &Fixed {
      @extend .navigation__button;

      position: fixed;
      right: 12px;
      top: 22px;
    }
  }

  &__bar {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 7px 0;
    transition: 0.4s;
  }
}
