@keyframes slideIn {
  from {
    transform: scale(0.7);
  }

  to {
    transform: scale(1);
  }
}

.rent {
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
  animation-duration: 0.5s;
  animation-name: slideIn;

  &__button {
    margin-top: 35px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 50px;
    background: var(--color-blue);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: var(--color-white);
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-md);
    text-align: center;
    text-decoration: none;
    border: none;
    outline-style: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (min-width: 730px) {
      width: 292px;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    position: relative;

    &Photo {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      border: 5px solid #ffffff;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
      margin-bottom: 0;
      opacity: 0.7;

      &Main {
        @extend .rent__boxPhoto;

        width: 160px;
        height: 160px;
        position: absolute;
        top: 20px;
        left: 82px;
        opacity: 1;
        z-index: 100;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    height: 200px;
    position: relative;
    margin: 50px 0 29px 0;
  }

  &__paragraph {
    font-weight: var(--font-primary-regular);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    text-align: center;
    margin: 0 auto 0 auto;
    max-width: 590px;

    &Bold {
      @extend .rent__paragraph;

      font-weight: var(--font-primary-bold);
    }
  }

  &__title {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
    text-align: center;
    margin-bottom: 10px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 600px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  &__arrowLeft {
    position: relative;
    right: 20px;
    height: 16px;

    @media screen and (min-width: 730px) {
      right: 40px;
    }
  }

  &__arrowRight {
    position: relative;
    left: 20px;
    height: 16px;

    @media screen and (min-width: 730px) {
      left: 40px;
    }
  }
}
