.mapbox-logo {
  pointer-events: none;
}
.mapboxgl-ctrl-logo {
  pointer-events: none;
}
.mapbox-improve-map {
  pointer-events: none;
}
.mapboxgl-ctrl-compass {
  pointer-events: none;
}

.mapboxgl-ctrl-attrib-inner {
  pointer-events: none;
}

.map {
  border-radius: 10px;

  &__point {
    width: 60px;
    height: 80px;
  }
}
