@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  animation: fadeIn 0.5s;
}

.section {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.box {
  width: 1920px;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;

  @media screen and (min-width: 490px) {
    min-height: 100vh;
    max-height: 100%;
    overflow-y: auto;
  }
}

.image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: fadeIn 0.5s;

  &Fixed {
    @extend .image;

    position: fixed;
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }
}

.cookie {
  &__buttonClose {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: var(--font-size-mx);
    font-weight: var(--font-primary-bold);
    color: white;
    background-color: inherit;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
  }

  &__buttonTerm {
    @extend .cookie__buttonClose;

    position: inherit;
    text-decoration: underline;
    font-size: var(--font-size-md);
    font-weight: var(--font-primary-regular);

    &:hover {
      opacity: 0.8;
    }
  }
}
