@keyframes slideIn {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 620px;
  padding-left: 0px;
  margin-left: auto;
  margin-right: auto;
  animation-duration: 0.5s;
  animation-name: slideIn;

  @media screen and (min-width: 750px) {
    padding-left: 12px;
    max-width: 680px;
  }

  @media screen and (min-width: 820px) {
    padding-left: 62px;
    max-width: 720px;
  }

  &__scroll {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: var(--color-gray-modal);
      border-radius: 6px;
      margin: 25px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-blue);
      border-radius: 6px;
    }
  }

  &__showMore {
    max-height: auto;

    @media screen and (min-width: 750px) {
      max-height: 220px;
    }
  }

  &__date {
    width: 215px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--color-white);
    color: var(--color-white);
    background-color: inherit;
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    letter-spacing: 1px;
    padding-left: 28px;

    &Error {
      @extend .form__date;

      border: 1px solid red;
    }

    &:focus {
      outline-style: inherit;
      border: 1px solid var(--color-blue);
    }
  }

  &__error {
    visibility: hidden;
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-s);
    color: red;

    &Active {
      @extend .form__error;

      visibility: visible;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: 750px) {
      flex-direction: row;
    }
  }

  &__title {
    margin-top: 40px;
    font-size: var(--font-size-l);
  }

  &__field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 45px;

    &Label {
      font-weight: var(--font-primary-bold);
      font-size: var(--font-size-md);
      line-height: var(--line-height-md);
      margin-top: 20px;
    }

    &Input {
      width: 290px;
      height: 50px;
      border-radius: 10px;
      border: 1px solid var(--color-white);
      color: var(--color-white);
      background-color: inherit;
      font-weight: var(--font-primary-bold);
      font-size: var(--font-size-md);
      line-height: var(--line-height-md);
      letter-spacing: 1px;
      padding-left: 28px;
      padding-right: 28px;

      @media screen and (min-width: 490px) {
        width: 340px;
      }

      &Textarea {
        @extend .form__fieldInput;

        max-width: 600px;
        height: 145px;
        padding-top: 6px;
        padding-left: 18px;
        padding-right: 18px;
        margin-bottom: 40px;

        @media screen and (min-width: 730px) {
          width: 600px;
        }
      }

      &Small {
        @extend .form__fieldInput;

        width: 215px;
      }

      &:focus {
        outline-style: inherit;
        border: 1px solid var(--color-blue);
      }
    }
  }

  .input {
    @extend .form__fieldInput;

    display: flex;
    align-items: center;
    color: var(--color-gray-light);
    padding-left: 20px;
    padding-right: 0;

    &Error {
      @extend .input;

      border: 1px solid red;
    }

    &:focus-within {
      outline-style: inherit;
      border: 1px solid var(--color-blue);
    }

    @media screen and (min-width: 490px) {
      width: 340px;
    }

    &__flag {
      width: 28px;
      margin-right: 3px;
    }

    &__phone {
      @extend .input;

      color: var(--color-white);
      padding-left: 5px;
      margin-left: 5px;
      width: 210px;
      border: none;
      letter-spacing: 2px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      @media screen and (min-width: 490px) {
        width: 270px;
      }

      &:focus-within {
        outline-style: inherit;
        border: none;
      }

      &:hover {
        outline-style: inherit;
        border: none;
      }
    }
  }

  .term {
    display: flex;
    margin-top: 20px;

    &__bird {
      visibility: hidden;
      margin-top: 2px;

      &Visible {
        visibility: visible;
        margin-top: 2px;
      }
    }

    &__button {
      width: 26px;
      height: 25px;
      margin-top: 5px;
      margin-right: 20px;
      border-radius: 4px;
      border: 2px solid var(--color-white);
      background-color: inherit;

      &:focus {
        outline: none;
      }

      @media screen and (min-width: 730px) {
        margin-right: 40px;
      }
    }

    &__buttonParagraph {
      color: var(--color-blue);
      text-decoration: underline;
      font-size: var(--font-size-s);
      border: none;
      background-color: inherit;
      cursor: pointer;

      @media screen and (min-width: 490px) {
        font-size: var(--font-size-md);
      }
    }

    &__paragraph {
      font-size: var(--font-size-s);
      line-height: var(--line-height-md);
      max-width: 550px;

      @media screen and (min-width: 490px) {
        font-size: var(--font-size-md);
      }
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 600px;
  margin-top: 14px;

  &__arrowLeft {
    position: relative;
    right: 20px;
    height: 16px;

    @media screen and (min-width: 730px) {
      right: 40px;
    }
  }

  &__arrowRight {
    position: relative;
    left: 20px;
    height: 16px;

    @media screen and (min-width: 730px) {
      left: 40px;
    }
  }
}
