.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 60px;

  @media screen and (min-width: 490px) {
    margin-top: 0;
  }

  @media screen and (min-width: 660px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &Offer {
    @extend .description;

    margin-top: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;

    @media screen and (min-width: 1040px) {
      justify-content: space-between;
    }
  }

  &__button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 50px;
    background: var(--color-blue);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: var(--color-white);
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-md);
    text-align: center;
    text-decoration: none;
    border: none;
    outline-style: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (min-width: 730px) {
      width: 292px;
    }
  }

  &__box {
    max-width: 785px;
  }

  &__photo {
    min-width: 222px;
    min-height: 222px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 6px solid #ffffff;

    @media screen and (min-width: 660px) {
      margin-top: 1.5rem;
      margin-right: 3rem;
    }

    @media screen and (min-width: 996px) {
      min-width: 387px;
      min-height: 387px;
    }

    &Small {
      width: 190px;
      height: 190px;
      border-radius: 50%;
      border: 6px solid #ffffff;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
      margin: 0 5px 5px 5px;
    }
  }

  &__paragraph {
    font-weight: var(--font-primary-regular);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    margin-bottom: 5px;

    &Bold {
      @extend .description__paragraph;

      margin-bottom: 0;
      font-weight: var(--font-primary-bold);

      @media screen and (min-width: 490px) {
        &:last-child {
          margin-bottom: 100px;
        }
      }
    }
  }

  &__service {
    text-align: center;
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    color: var(--color-gray);
    margin-bottom: 5px;
    width: 190px;

    &Paragraph {
      @extend .description__service;

      font-weight: var(--font-primary-regular);
      font-size: var(--font-size-s);
      line-height: var(--line-height-s);
      margin-bottom: 30px;
    }
  }

  &__title {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    margin-bottom: 1rem;
  }
}
