.header {
  z-index: 500;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.65) 10%,
    rgba(196, 196, 196, 0) 90%,
    rgba(0, 0, 0, 0) 90%
  );

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: 1320px;
    height: 90px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--color-white);
  }

  &__logo {
    width: 220px;
    height: 40px;
    opacity: 1;
    transition: ease 0.5s all;
  }

  &__logoHide {
    opacity: 0;
  }

  &__logoPlaceHolder {
    width: 30px;
    height: 30px;
    background-color: var(--color-white);
    border-radius: 50%;
  }
}

.country {
  display: none;

  @media screen and (min-width: 996px) {
    display: flex;
    align-items: center;
  }

  &__button {
    background-color: inherit;
    border: none;
    outline-style: none;
  }

  &__flag {
    width: 31px;
    height: 26px;
  }

  &__name {
    font-weight: var(--font-primary-bold);
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-left: 0.6rem;
  }
}
