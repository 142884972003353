@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1000;
  animation: fadeIn 0.5s;
}

.offer {
  margin: 10vh auto;
  min-height: 100vh;
  animation: fadeIn 0.5s;
  &__iframe {
    border: 0;
    width: 100% !important;
    padding: 0;
    margin: 0 auto;
    min-height: 200px;
    max-width: 1000px;
    display: block;
  }

  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6vh;
    background-color: rgba(0, 0, 0, 0.5);
    max-width: 400px;
    border-radius: 8px;
    padding: 10px 0;
  }
}
